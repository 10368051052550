import { FormControl, FormLabel, IconButton, Input, InputGroup, InputProps, InputRightElement, useDisclosure, useMergeRefs } from "@chakra-ui/react";
import { forwardRef, useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useField } from "remix-validated-form";
import { FieldFooter } from "app/components/FieldFooter";

interface ValidatedPasswordProps extends InputProps {
  helperText?: string;
  label?: string;
}

export const ValidatedPassword = forwardRef<HTMLInputElement, ValidatedPasswordProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const { error, getInputProps } = useField(props.name || "");

  return (
    <FormControl isInvalid={error !== undefined}>
      <FormLabel htmlFor="password">{props.label ?? "Password"}</FormLabel>
      <InputGroup>
        <Input
          {...getInputProps<InputProps>({ id: props.name || "" })}
          ref={mergeRef}
          name="password"
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          required
          {...props}
        />
        <InputRightElement>
          <IconButton
            variant="link"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
            colorScheme="gray"
          />
        </InputRightElement>
      </InputGroup>
      <FieldFooter error={error || ""} helper={props.helperText} />
    </FormControl>
  );
});

ValidatedPassword.displayName = "ValidatedPassword";

export default ValidatedPassword;
